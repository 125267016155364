import { logtoClient, resource } from "./client";
import anime from 'animejs/lib/anime.es.js';
// import indexUrl from '../index.html';
// console.log(indexUrl);



// logtoClient.isAuthenticated().then((isAuthenticated) => {

//     if (!isAuthenticated) {
//         // const domain port and scheme
//         const domain = window.location.origin;
//         logtoClient.signIn(`${domain}/callback.html`).then(() => {
//             console.log('Logged in!');
//         });
//     } else {
//         //user logged in
//         console.log('Logged in!');
//         console.log("Refresh Token:", await logtoClient.getRefreshToken())
//     }




// });

// interface Apps {
//     name: string;
//     iconUrl: string;
//     url: string;
// }

// function cardLoading(mainCard: HTMLDivElement) {
//     const mainLoader = mainCard.querySelector(".loader") as HTMLDivElement;
//     const mainContent = mainCard.querySelector(".content") as HTMLDivElement;
//     anime({
//         targets: mainContent,
//         height: 0,
//         scaleY: 0,
//         duration: 500,
//         easing: 'easeInOutQuad',

//     });
//     anime({
//         targets: mainLoader,
//         height: "100%",
//         scaleY: 1,
//         duration: 500,
//         easing: 'easeInOutQuad',

//     })
// }

// function cardLoadingComplete(mainCard: HTMLDivElement) {
//     const mainLoader = mainCard.querySelector(".loader") as HTMLDivElement;
//     const mainContent = mainCard.querySelector(".content") as HTMLDivElement;
//     mainContent.style.display = "block";
//     requestAnimationFrame(() => {
//         anime({
//             targets: mainLoader,
//             height: "0%",
//             scaleY: 0,
//             duration: 500,
//             easing: 'easeInOutQuad',

//         });
//         anime({
//             targets: mainContent,
//             height: "auto",
//             scaleY: 1,
//             duration: 500,
//             easing: 'easeInOutQuad',

//         })
//     });
// }

const avatar = document.querySelector('.avatar > img') as HTMLImageElement;
const preferredName = document.querySelector('#preferred-name') as HTMLInputElement;
const phone = document.querySelector('#phone') as HTMLInputElement;
const mainCard = document.querySelector('main') as HTMLDivElement;
const mainLoader = mainCard.querySelector(".loader") as HTMLDivElement;
const mainContent = mainCard.querySelector(".content") as HTMLDivElement;

// cardLoading(mainCard);

const main = async () => {


    const isAuthenticated = await logtoClient.isAuthenticated();
    if (!isAuthenticated) {
        // const domain port and scheme
        // const scheme = window.location.protocol;
        const domain = window.location.origin;
        console.log(`${domain}/callback.htm`)
        logtoClient.signIn(`${domain}/callback.htm`).then(() => {
            console.log('Logged in!');
        });
    } else {
        //user logged in
        console.log('Logged in!');
        // console.log("Refresh Token:", await logtoClient.getRefreshToken());
        console.log("Access Token:", await logtoClient.getAccessToken(resource));
        console.log("Access Token Claims", await logtoClient.getAccessTokenClaims(resource));
        console.log("ID Token:", await logtoClient.getIdToken());
        console.log("ID Token Claims:", await logtoClient.getIdTokenClaims());
        console.log("User Info:", await logtoClient.fetchUserInfo());
        const user = await logtoClient.fetchUserInfo();
        

        avatar.src = user.picture;
        preferredName.value = user.name ?? "";
        phone.value = user.phone_number ?? "";
        user.custom_data = user.custom_data as Record<string, any>;
        const apps = (user.custom_data as any)?.apps as string[] | undefined;

        if(apps?.includes("construct")) {
            const construct = document.querySelector("#app-construct") as HTMLAnchorElement;
            construct.style.display = "block";
        }

        // cardLoadingComplete(mainCard);
        
        // logtoClient.
    }

}

main();